<template>
  <v-app
    style="background: #E3F2FD;
        background: linear-gradient(to bottom, #047c44, #05ad5f, #c2c2d6);"
    fixed-tabs
  >
    <!-- login ไม่เชื่อม OneID -->
    <section class="pt-15">
      <v-container>
        <v-row no-gutters>
          <v-col class="text-center pa-0" cols="12" md="6">
            <h2
              class="text-center ml-0 mt-0 mb-0 white--text"
              style="font-size:45px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
            >
              MohPromt
            </h2>
            <h2
              class="text-center ml-0 mb-0 white--text"
              style="font-size:21px; text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
            >
              Digital Health Platform
            </h2>
            <v-row class="fill-height white--text pt-5" justify="center">
              <v-col cols="12">
                <v-container grids-list-xs>
                  <img
                    v-if="resolutionScreen >= 400"
                    src="@/assets/logo_mohpromt2.png"
                    width="450px"
                  />
                  <img v-else src="@/assets/logo_mohpromt2.png" width="300px" />
                </v-container>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="6"
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <h2
              class="mt-1 mb-10 white--text"
              style="font-size:36px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
            >
              <!-- REGISTER -->
              {{ $t("landingpage.registeruserheader") }}
            </h2>
            <v-stepper
              style="background: #F4DA5B;"
              v-model="e1"
              class="mb-5 ml-9"
            >
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                  <!-- ลงทะเบียนผู้ใช้งาน -->
                  {{ $t("landingpage.registeruserheader") }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                  <!-- เสร็จสิ้น -->
                  {{ $t("landingpage.success") }}
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            <div>
              <!-- label="รหัสบัตรประชาชน / IDCard" -->
              <v-text-field
                clearable
                solo
                outlined
                dense
                class="pa-1 pt-5 pb-0"
                v-model="id_card_num"
                :label="$t('landingpage.idcard')"
                maxlength="13"
                prepend-icon="credit_card"
              ></v-text-field>
              <!-- label="เบอร์โทรศัพท์ / Mobile No." -->
              <v-text-field
                clearable
                solo
                outlined
                dense
                class="pa-1 pt-5 pb-5"
                v-model="phonenumber"
                :label="$t('landingpage.mobileno')"
                maxlength="10"
                prepend-icon="phone"
              ></v-text-field>
              <v-col class="col-md-6 col-sm-6">
                <v-btn
                  @click="fn_register()"
                  rounded
                  block
                  style="font-size:16px;font-weight:600; background-color:#F4DA5B; color:#005799; text-shadow: rgba(0, 0, 0, 0.25)"
                >
                  <!-- ลงทะเบียน -->
                  {{ $t("landingpage.verify") }}
                </v-btn>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>

<script>
export default {
  data: function() {
    return {
      e1: 1,
      phonenumber: "",
    };
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
};
</script>

<style></style>
